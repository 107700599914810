import { useStore } from "/store/store.js";
import React, { useEffect } from "react";
import "../styles/index.scss";
import Head from "next/head";
import { useRouter } from "next/router";
import { SWRConfig } from "swr";
import mpTag from "scripts/tag/mpTag";

/*
function localStorageProvider() {
  if (typeof window === "undefined") {
    return new Map([]);
  }
  //when initializing, we restore the data from `localStorage` into a map.
  const map = new Map(JSON.parse(localStorage.getItem("app-cache") || "[]"));

  //before unloading the app, we write back all the data into `localStorage`.
  window.addEventListener("beforeunload", () => {
    const appCache = JSON.stringify(Array.from(map.entries()));
    localStorage.setItem("app-cache", appCache);
  });

  //we still use the map for write & read for performance.
  return map;
}
*/

function MyApp({ Component, pageProps: { session, ...pageProps } }) {
  const { store, persistor } = useStore(pageProps.initialReduxState);
  const router = useRouter();


  useEffect(() => {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.register("/serviceWorker.js").then(function(registration) {
        console.log("Service Worker registration successful with scope: ", registration.scope);
      },
      function(err) {
        console.log("Service Worker registration failed: ", err);
      });
    }


    function routeChangeStart(url){
      if (window.handlePageExit){
        window.handlePageExit();
      }
    }

    //handle page view
    function routeChangeComplete(url){
      mpTag("page-view");
      window.pageId.url = url;
      window.pageExited = false;
      window.pageViewStartTime = Date.now();
    }

    //gtm


    //authenticate the user
    //authenticateClient(store.dispatch);

    router.events.on("routeChangeStart", routeChangeStart);
    router.events.on("routeChangeComplete", routeChangeComplete);
    return () => {
      router.events.off("routeChangeStart", routeChangeStart);
      router.events.off("routeChangeComplete", routeChangeComplete);
    };
  }, [store.dispatch, router.pathname, router.events]);

  //gtm
  useEffect(() => {
    setTimeout(() => {
      (function(w, d, s, l, i){
        //eslint-disable-next-line max-statements-per-line
        w[l] = w[l] || []; w[l].push({"gtm.start":
      new Date().getTime(),
        //eslint-disable-next-line max-statements-per-line
        event: "gtm.js"}); var f = d.getElementsByTagName(s)[0],
          //eslint-disable-next-line max-statements-per-line, eqeqeq
          j = d.createElement(s), dl = l != "dataLayer" ? `&l=${ l}` : ""; j.async = true; j.src =
      //eslint-disable-next-line max-statements-per-line
      `https://www.googletagmanager.com/gtm.js?id=${ i }${dl}`; f.parentNode.insertBefore(j, f);
      })(window, document, "script", "dataLayer", "GTM-PHWSFMG");
    }, 2000);
  }, []);


  return (
    <>
      <Head>

        <link rel="preload" href="/fonts/1Ptsg8zYS_SKggPNyCg4TYFq.woff2" as="font" type="font/woff2" crossOrigin="true"/>
        <link rel="preload" href="/fonts/1Ptug8zYS_SKggPNyC0ITw.woff2" as="font" type="font/woff2" crossOrigin="true"/>
        <link rel="preload" href="/fonts/8vIU7ww63mVu7gtR-kwKxNvkNOjw-jHgfY3lDQ.woff2" as="font" type="font/woff2" crossOrigin="true"/>


        <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, viewport-fit=cover" />

        <meta name="application-name" content="X Marks the Site" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="default" />
        <meta name="apple-mobile-web-app-title" content="X Marks the Site" />
        <meta name="description" content="Find and complete the ten X Challenges to win prizes." />
        <meta name="format-detection" content="telephone=no" />
        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="msapplication-TileColor" content="#f0cf75" />
        <meta name="msapplication-tap-highlight" content="no" />
        <meta name="theme-color" content="#000000" />

        <link rel="apple-touch-icon" href="/images/logos/x/192x192.png" />
        <link rel="apple-touch-icon" sizes="152x152" href="/images/logos/x/152x152.png" />
        <link rel="apple-touch-icon" sizes="180x180" href="/images/logos/x/180x180.png" />
        <link rel="apple-touch-icon" sizes="167x167" href="/images/logos/x/167x167.png" />

        <link rel="icon" type="image/png" sizes="64x64" href="/images/logos/x/64x64.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/images/logos/x/32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/images/logos/x/16x16.png" />
        <link rel="manifest" href="/manifest.json" />
        <link rel="shortcut icon" href="/images/logos/x/512x512.png" />

        <meta name="twitter:card" content="summary" />
        <meta name="twitter:url" content="https://xmarksthe.site" />
        <meta name="twitter:title" content="X Marks the Site" />
        <meta name="twitter:description" content="Find and complete the ten X Challenges to win prizes." />
        <meta name="twitter:image" content="https://xmarksthe.site/images/logos/x/192x192.png" />
        <meta name="twitter:creator" content="@abadthink" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="X Marks the Site" />
        <meta property="og:description" content="Find and complete the ten X Challenges to win prizes." />
        <meta property="og:site_name" content="X Marks the Site" />
        <meta property="og:url" content="https://xmarksthe.site" />
        <meta property="og:image" content="https://xmarksthe.site/images/logos/x/192x192.png" />

        <script type="text/javascript" dangerouslySetInnerHTML={{ __html: process.env.tagJs }}></script>
      </Head>
      <SWRConfig value={{ }}>
        <Component {...pageProps} />
      </SWRConfig>
    </>
  );
}

export default MyApp;
