export default function reducer(state, action){
  return {
    ...state,
    notifications: state.notifications.map((notif) => {
      if (notif.id !== action.value.id){
        return notif;
      }
      return {
        ...notif,
        ...action.value,
      };
    }),
  };
}
