import { useMemo } from "react";
import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; //defaults to localStorage for web

import user from "./actions/user/index.js";
import notifications from "./actions/notifications/index.js";


let store;

const initialState = {
  tooltip: {
    name: "",
    event: null,
    timeOpened: null,
  },
  user: null,
  notifications: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
  case "TOOLTIP_SET":
    return {
      ...state,
      tooltip: {
        ...action.value,
        timeOpened: Date.now(),
      },
    };
  case "USER_SET":
    return user.set(state, action);
  case "NOTIFICATION_ADD":
    return notifications.add(state, action);
  case "NOTIFICATION_REMOVE":
    return notifications.remove(state, action);
  case "NOTIFICATION_UPDATE":
    return notifications.update(state, action);
  default:
    return state;
  }
};

function initStore(preloadedState = initialState) {
  const persistConfig = {
    key: "root",
    storage,
    blacklist: ["tooltip", "notifications"],
  };

  const persistedReducer = persistReducer(persistConfig, reducer);

  let createdStore = createStore(persistedReducer, preloadedState, composeWithDevTools(applyMiddleware()));
  let persistor = persistStore(createdStore);
  return {store: createdStore, persistor};
}

export const initializeStore = (preloadedState) => {
  let _store = store ?? initStore(preloadedState);

  //after navigating to a page with an initial Redux state, merge that state
  //with the current state in the store, and create a new store
  if (preloadedState && store) {
    _store = initStore({
      ...store.getState(),
      ...preloadedState,
    });
    //reset the current store
    store = undefined;
  }

  //for SSG and SSR always create a new store
  if (typeof window === "undefined") {
    return _store;
  };
  //create the store once in the client
  if (!store) {
    store = _store;
  };

  return _store;
};

export function useStore(initState) {
  const storeToUse = useMemo(() => initializeStore(initState), [initState]);
  return storeToUse;
}
