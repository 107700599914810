import add from "./add/index.js";
import remove from "./remove/index.js";
import update from "./update/index.js";

const reducers = {
  add,
  remove,
  update,
};

export default reducers;
